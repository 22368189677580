<template>
    <div class="row">
        <div class="col-md-12">
            <button style="" @click="$router.push('slc')" class="btn btn-primary">Add</button>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Address</th>
                        <th>Phone</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="serviceLoc in ServiceLocs" :key="serviceLoc.key">
                        <td>{{ serviceLoc.name }}</td>
                        <td>{{ serviceLoc.notes }}</td>
                        <td>{{ serviceLoc.phone }}</td>
                        <td>
                            <router-link :to="{name: 'serviceLocEdit', params: { id: serviceLoc.key }}" class="btn btn-primary">Edit
                            </router-link>
                            <button @click.prevent="deleteServiceLoc(serviceLoc.key)" class="btn btn-danger">Delete</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import { db } from '../firebaseDb';
    export default {
        data() {
            return {
                ServiceLocs: [],
                currentTenant: "",
                service: {},
                serviceid: "",
                newCem: {},
            }
        },
        
        created() {
            this.currentTenant = this.$tenant;
            this.updateservices();
        },
        methods: {
            updateservices() {
                db.collection('serviceLocs').orderBy('name').onSnapshot((snapshotChange) => {
                this.ServiceLocs = [];
                snapshotChange.forEach((doc) => {
                    if(this.currentTenant == doc.data().tenant && doc.data().deleted !== true) {
                    this.ServiceLocs.push({
                        key: doc.id,
                        name: doc.data().name,
                        notes: doc.data().notes,
                        phone: doc.data().phone,
                        
                    })
                    }
                });
            })
            },
            deleteServiceLoc(id){
              if (window.confirm("Do you really want to delete?")) {
                  this.newCem = this.ServiceLocs.find(x => x.key === id)
                this.newCem.deleted = true;
                db.collection("serviceLocs").doc(id).update(this.newCem).then(() => {
                    console.log("Service Location Deleted!");
                })
                .catch((error) => {
                    console.error(error);
                })
              }
            }
        }
    }
</script>

<style>
    .btn-primary {
        margin-right: 12px;
    }
</style>